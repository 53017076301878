<template>
  <el-form
    class="auditModel"
    :model="auditForm"
    label-width="120px"
    :rules="rules"
    size="small"
    ref="auditForm"
  >
    <el-row v-if="!isOffShelf">
      <el-col :span="8">
        <span class="colorBlack fw7">语言：{{ auditInfo.languageSum }}</span>
      </el-col>
      <el-col :span="8">
        <span class="colorBlack fw7">已审核：{{ auditInfo.yesReviewNum }}</span>
      </el-col>
      <el-col :span="8">
        <span class="colorRed fw7">待审核：{{ auditInfo.stayReviewNum }}</span>
      </el-col>
    </el-row>
    <el-form-item label="选择国家/地区" prop="languageId" v-if="!isOffShelf">
      <el-select
        clearable
        size="small"
        v-model="auditForm.languageId"
        placeholder="语言"
      >
        <el-option
          v-for="(item, index) in languageList"
          :key="index"
          :value="item.languageId"
          :label="item.languageName"
          :disabled="item.reviewStatus !== 100"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="审核结果">
      <el-radio-group v-model="auditForm.pass">
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="审核意见">
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="auditForm.reviewComment"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="文件上传">
      <UploadFile
        type="other"
        class="ml10"
        @success="uploadSuccess"
        v-model="auditForm.accessPath"
      ></UploadFile>
      <div class="ml10" v-show="auditForm.fileName">
        <span>{{ auditForm.fileName }}</span>
        <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
      </div>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="submitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import { appointmentAudit, outReviewInfo } from "@/common/js/api";
import storage from "@/common/utils/storage";
import { auditLanguageInfo } from "@/common/js/commonApi";
export default {
  components: { UploadFile },
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      auditForm: {
        pass: 1,
        reviewComment: "",
        accessPath: "",
        languageId: "",
        fileId: "",
        fileName: "",
      },
      languageList: [],
      rules: {
        languageId: {
          required: true,
          message: "请选择语言",
          trigger: "change",
        },
      },
      disabled: false,
      auditInfo: {},
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isOffShelf() {
      return this.checkData.releaseStatus === 640;
    },
  },
  methods: {
    init() {
      this.auditLanguageInfo();
    },
    uploadSuccess(val) {
      this.auditForm.accessPath = val.accessPath;
      this.auditForm.fileId = val.fileId;
      this.auditForm.fileName = val.fileName;
    },
    handleRemoveFile() {
      this.auditForm.accessPath = "";
      this.auditForm.fileId = "";
      this.auditForm.fileName = "";
    },

    async submitAudit() {
      let valid = await new Promise((resolve, rej) =>
        this.$refs.auditForm.validate(resolve)
      );
      if (!valid) return;
      let params = JSON.parse(JSON.stringify(this.auditForm));
      params.resolveUserW3Id = storage.getUserInfo().userId;
      params.releaseId = this.checkData.releaseId;
      if (this.isOffShelf) {
        params.ids = [this.checkData.releaseId];
        params.reviewResult = params.pass;
      }
      const fnc = this.isOffShelf ? outReviewInfo : appointmentAudit;
      const res = await fnc(params);
      if (res && res.code === 0) {
        this.$message.success("审核成功");
        this.$emit("submitAudit");
      }
    },
    async auditLanguageInfo() {
      const res = await auditLanguageInfo({
        releaseId: this.checkData.releaseId,
      });
      this.auditInfo = res.data;
      this.languageList = res.data.languages;
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 50%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
