<template>
  <div class="viewDetail">
    <el-form
      class="dialogForm"
      size="small"
      label-width="200px"
      style="height: 500px; overflow: scroll; overflow-x: hidden"
      v-if="Object.getOwnPropertyNames(honorAppReservedApply).length"
    >
      <el-form-item label="预约应用包名" class="requireClass">
        <el-input
          disabled
          v-model="honorAppReservedApply.packageName"
          class="latter"
          show-word-limit
          maxlength="64"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="首发精准时间" class="requireClass">
        <el-input
          disabled
          v-model="honorAppReservedApply.firstPublishTime"
          autocomplete="off"
        ></el-input>
        <el-tooltip
          content="建议与您的游戏开服时间一致。"
          placement="top"
          effect="light"
        >
          <i
            class="el-icon-warning-outline tooltip-position"
            style="font-size: 20px; color: rgb(204, 204, 204)"
          ></i>
        </el-tooltip>
        <div class="remark-text">
          此时间为本地时间，请谨慎填写首发时间，预约审核通过后，若您多次更改首发时间，将会影响预约资源位。首发时间与预约静默安装相关，首发时间如有调整请及时更新。
        </div>
      </el-form-item>
      <el-form-item label="联系人及联系方式" class="requireClass">
        <el-input
          disabled
          type="textarea"
          :rows="6"
          v-model="honorAppReservedApply.contactInfo"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布国家及地区：" class="requireClass">
        <el-cascader
          clearable
          size="small"
          :disabled="true"
          :options="areaList"
          v-model="releaseArea"
          placeholder="请选择发布国家或地区"
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="评测APK包">
        <el-input
          disabled
          v-model="honorAppReservedApply.apkUrl"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="评测高等级账号">
        <el-input
          disabled
          v-model="honorAppReservedApply.evaluateAccount"
          show-word-limit
          maxlength="50"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          disabled
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 8 }"
          v-model="honorAppReservedApply.remark"
          show-word-limit
          maxlength="200"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <div v-if="showLanguageData">
        <el-form-item label="语言" class="requireClass">
          <el-select v-model="selectLanguage" placeholder="请选择">
            <el-option
              v-for="(item, index) in honorAppReservedInfos"
              :key="index"
              :label="item.languageName"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用名称" class="requireClass">
          <el-input
            disabled
            v-model="showLanguageData.appName"
            autocomplete="off"
          >
          </el-input>
          <div class="remark-text">简体中文 - 默认</div>
        </el-form-item>
        <el-form-item label="预计上线时间">
          <el-input
            disabled
            v-model="showLanguageData.expectPublishTime"
            placeholder="例如：2020年Q1/2020年1月/敬请期待"
            show-word-limit
            maxlength="30"
            autocomplete="off"
          >
          </el-input>
          <div class="remark-text formItemRemark">
            用于客户端外显时间，便于您无法确定首发日期时使用。要求该时间需与“首发精确时间”保持在同一时间段内。简体中文
            - 默认
          </div>
        </el-form-item>
        <el-form-item label="预约大图" class="requireClass">
          <img class="appointmentImg" :src="showLanguageData.largeImg" alt="" />
        </el-form-item>
        <el-form-item label="生效时间" class="requireClass">
          <el-radio-group disabled v-model="honorAppReservedApply.effectType">
            <el-radio :label="0">审核通过立即生效</el-radio>
            <el-radio :label="1">指定时间</el-radio>
          </el-radio-group>
          <el-date-picker
            disabled
            v-show="honorAppReservedApply.effectType == 1"
            style="width: 220px"
            class="ml10"
            v-model="honorAppReservedApply.effectTime"
            prefix-icon="el-icon-date"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "viewDetail",
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogFormVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    honorAppReservedApply: {
      type: Object,
      default() {
        return {};
      },
    },
    honorAppReservedInfos: {
      type: Array,
      default() {
        return [];
      },
    },
    propReleaseArea: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.getArrReleaseArea(this.propReleaseArea);
    this.$store.dispatch("updateAreaListList");
  },
  data() {
    return {
      selectLanguage: 0,
      releaseArea: [],
      areaProps: {
        value: "countryId",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
      },
    };
  },
  computed: {
    ...mapGetters(["areaList"]),
    showLanguageData() {
      return this.honorAppReservedInfos[this.selectLanguage];
    },
  },
  methods: {
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = await this.$store.dispatch("updateAreaListList");
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const parentkey = this.getParentKey(key, areaList);
          arr[index] = [parentkey, key];
        });
      }
      this.releaseArea = arr;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] === childrenKey
          );
          if (obj) {
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
  },
  watch: {
    propReleaseArea(idList) {
      this.getArrReleaseArea(idList);
    },
  },
};
</script>
<style scoped lang="scss">
.appointmentImg {
  width: 300px;
  height: 200px;
}
.dialogForm {
  /deep/.requireClass {
    .el-form-item__label::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss">
.dialogForm {
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
